

.Seed{
z-index:12;
background-color:red;

}


@media (prefers-reduced-motion: no-preference) {
    .Seed {
      animation: Seed_Float infinite 3s linear,
      Seed_Glow infinite 2s linear;
    }

    .Glow_Seed {
      animation: Seed_Float infinite 3s ease,
      Seed_Glow infinite 2s linear;
    }
  }


@keyframes Seed_Float {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
  }
  

  @keyframes Seed_Glow{
    0% {opacity: 0.95;}
    25% {opacity: 0.75;}
    50%{opacity:.85;}
  
    100%{opacity:0.95;}
  } 

  .Seed_Glow:after{
    background: rgba(170, 221, 255, 0.5);
    border: 1px solid rgba(170, 221, 255, 0.5);
    border-radius: 3px;
  }