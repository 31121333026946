@font-face {
    src: url(../assets/arlrdbd.ttf);
    font-family: arialMT;
}


.container, .backView {
    display: grid;
    max-width: 100vw;
}

.backView {
    grid-column: 1;
    grid-row: 1;
    z-index: -1;
}

.bgImg {
    background: url('../assets/elle_full_close.png');
    background-size: cover;
    width: 100vw;
    height: 100vh;
    transition-duration: 0.6s;
}


.frontView {
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
}

.downloadContainer {
    grid-column: 1;
    grid-row: 1;
    z-index: 4;
}

.downloadButton {
    width: 16vw;
    height: 16vw;
    left: 59.4%;
    top: 31.6vw;
    position: relative;
    background: transparent;
    border: none !important;
    font-size:0;
    border-radius: 100%;
    box-shadow: none;
}


.downloadButton:hover {
    box-shadow: 0 0 100px #3FFFCA;
}

.gradientContainer, .backPicture, .frontParticles, .backParticles {
    grid-column: 1;
    grid-row: 1;
}

.gradientContainer {
    z-index: -1;
}
.backParticles {
    z-index: 0;
}
.backPicture {
    z-index: 1;
}
.frontParticles {
    z-index: 2;
}

.banner {
    position: relative;

    margin-top: 20vh;
    margin-left: 25vw;
}

.bannerImg {
    animation: bannerFloat infinite 3s ease-in-out;
}

.info {
    font-family: arialMT;
    font-size: 20px;
    width: 40vw;
    color: white;
    margin-left: 10vw;
}


@keyframes bannerFloat {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}

@media only screen and (max-width: 1000px) {
   .banner {
        margin-top: 13vh;
        margin-left: 13vh;
    }
}
@media only screen and (max-width: 850px) {
    .banner {
        margin-top: 10vh;
        margin-left: 10vh;
        font-size: 18px;
    }
}
@media only screen and (max-width: 775px) {
    .banner {
        margin-top: 2vh;
        margin-left: 2vh;
    }
}
@media only screen and (max-width: 650px) {
    .banner {
        margin-top: 2vh;
        margin-left: 0;
    }
    .bannerImg {
        width: 60%;
        height: auto;
    }
    .info {
        font-size: 14px;
    }
}


.seed {
    grid-column: 1;
    grid-row: 1;
    z-index: 2;
    width: 100vw;
    height: 100vh;
}
